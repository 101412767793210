import { createI18n } from 'vue-i18n'
import _ from 'lodash'

function loadLocaleMessages () {
  const locales = require.context('../locales', true, /\.json$/)
  const messages = {}

  locales.keys().forEach(key => {
    const keyPath = key.split('/').slice(1).join('.').replace(/\.json$/, '')
    _.set(messages, keyPath, locales(key))
  })

  return messages
}

const i18n = createI18n({
  locale: defaultLocale(),
  fallbackLocale: defaultLocale(),
  messages: loadLocaleMessages()
})

export default i18n

export function defaultLocale () {
  return 'en-US'
}

export function getLocale () {
  return i18n.global.locale
}

export function getAvailableLocales () {
  return ['en-US', 'fr-FR']
}

export function setLocale (locale) {
  i18n.global.locale = locale
  console.log(`[SET_LOCALE] ${locale}`)
}
