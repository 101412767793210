<template>
<div class="home-view pb-4">
  <main-banner/>
  <attraction-list/>
</div>
</template>

<script>
import MainBanner from '@/components/HomeView/MainBanner'
import AttractionList from '@/components/HomeView/AttractionList'

export default {
  components: { MainBanner, AttractionList }
}
</script>
