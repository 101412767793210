<template>
<div class="images-display mb-4">
  <div class="display-grid">
    <img v-for="(item, idx) of gridImages" :key="idx"
        :src="item"
        @click="handleShowImage(idx)">
  </div>
  <div class="display-swiper">
    <swiper :modules="modules"
        navigation
        :slides-per-view="1"
        :space-between="20">
      <swiper-slide v-for="(item, idx) of list" :key="idx">
        <img :src="item" @click="handleShowImage(idx)">
      </swiper-slide>
    </swiper>
  </div>
</div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from 'swiper'
import { Fancybox } from '@fancyapps/ui'

export default {
  components: { Swiper, SwiperSlide },

  props: {
    list: { type: Array }
  },

  data () {
    return {
      modules: [Navigation]
    }
  },

  beforeUnmount () {
    Fancybox.destroy()
  },

  computed: {
    gridImages () {
      return this.list.slice(0, 4)
    }
  },

  methods: {
    handleShowImage (idx) {
      Fancybox.show(
        this.list.map(it => ({ src: it, type: 'image' })),
        { startIndex: idx }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/bootstrap_tools";

.images-display {
  .display-grid {
    display: none;
    @include media-breakpoint-up (lg) {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: 3fr 1fr 1fr;
      grid-template-rows: repeat(2, 200px);
      grid-template-areas: 'a b b' 'a c d';
    }
    img {
      display: block;
      width: 100%; height: 100%;
      object-fit: cover;
      border-radius: 5px;
      &:nth-child(1) { grid-area: a; }
      &:nth-child(2) { grid-area: b; }
      &:nth-child(3) { grid-area: c; }
      &:nth-child(4) { grid-area: d; }
    }
  }
  .display-swiper {
    @include media-breakpoint-up (lg) {
      display: none;
    }
    img {
      display: block;
      width: 100%; height: 250px;
      object-fit: cover;
    }
  }
}
</style>
