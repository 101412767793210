<template>
<div class="show-view py-4" v-if="product">
  <div class="container">
    <nav class="mb-4">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'home' }">{{ $t('shared.breadcrumb.home') }}</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'list' }">{{ listViewTitle }}</router-link>
        </li>
        <li class="breadcrumb-item active">{{ $t('shared.breadcrumb.show_view') }}</li>
      </ol>
    </nav>

    <h4>{{ product.title }}</h4>

    <div class="reviews-block">
      <div class="rate">{{ product.rating }} <rate-star :value="product.rating"/></div>
      <div>{{ $t('shared.x_reviews', { count: product.totalReviews }) }}</div>
    </div>

    <images-display :list="imageList"/>

    <div class="row g-3">
      <div class="col-lg-8">
        <p>{{ product.description }}</p>
        <div class="row g-3">
          <div class="col-lg-8">
            <div class="tour-attr" v-if="productAttributes.hasFreeCancellation">
              <img src="@/assets/icons/free_cancellation.png">
              <div>
                <div>{{ $t('shared.free_cancellation') }}</div>
                <div class="small text-muted">{{ $t('shared.free_cancellation_desc') }}</div>
              </div>
            </div>
            <div class="tour-attr" v-if="productAttributes.hasMobile">
              <img src="@/assets/icons/mobile_ticketing.png">
              <div>
                <div>{{ $t('shared.mobile_ticketing') }}</div>
                <div class="small text-muted">{{ $t('shared.mobile_ticketing_desc') }}</div>
              </div>
            </div>
            <div class="tour-attr">
              <img src="@/assets/icons/duration.png">
              <div>
                <div>{{ $t('shared.duration') }} {{ productAttributes.duration }}</div>
                <div class="small text-muted">{{ $t('shared.duration_desc') }}</div>
              </div>
            </div>
            <div class="tour-attr" v-if="productAttributes.guideList.length">
              <img src="@/assets/icons/offered_in.png">
              <div>
                <div>{{ $t('shared.offered_in') }}</div>
                <div class="small text-muted">{{ productAttributes.guideList.join(", ") }}</div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="price-box">
              <div>{{ $t('shared.from') }}</div>
              <div class="price">US$ {{ product.price }}</div>
              <div>{{ $t('shared.per_person') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="qrcode" v-if="qrcodeVisible">
          <qrcode :value="`https://www.discovercity.com/tours/${product.id}`" :size="240"/>
          <div>{{ $t('show_view.scan_qrcode') }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Qrcode from 'qrcode.vue'
import RateStar from '@/components/RateStar'
import ImagesDisplay from '@/components/ShowView/ImagesDisplay'
import { findProduct } from '@/lib/data'
import { parseProductAttributes } from '@/lib/helper'

export default {
  components: { Qrcode, RateStar, ImagesDisplay },

  data () {
    return {
      listViewTitle: this.$t(`config.${window.APP_CONFIG.name}.list_title`),
      product: null,
      qrcodeVisible: location.hostname !== 'www.discovercity.com'
    }
  },

  async mounted () {
    this.product = await findProduct(parseInt(this.$route.params.id))
  },

  computed: {
    imageList () {
      if (!this.product) { return [] }
      return this.product.productImages.map(it => process.env.VUE_APP_DATA_URL + it.url)
    },

    productAttributes () {
      if (!this.product) { return null }
      return parseProductAttributes(this.product)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/bootstrap_tools";

.show-view {
  .reviews-block {
    display: flex; justify-content: space-between;
    margin-bottom: 20px;
    @include media-breakpoint-up (md) {
      justify-content: flex-start;
      .rate { margin-right: 20px; }
    }
  }
  .tour-attr {
    display: flex; align-items: flex-start;
    &:not(:last-child) { margin-bottom: 20px; }
    > img {
      width: 22px;
      margin-right: 10px;
    }
  }
  .price-box {
    border: 2px solid $primary;
    padding: 10px 20px;
    .price { font-size: 24px; font-weight: bold; }
  }
  .qrcode {
    text-align: center;
    img { margin-bottom: 10px; }
  }
}
</style>
