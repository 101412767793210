import axios from 'axios'
import _ from 'lodash'
import { getLocale, getAvailableLocales } from '@/lib/i18n'

let DATA = null

async function getData () {
  if (DATA) { return DATA }
  const { data } = await axios.get(process.env.VUE_APP_DATA_URL + window.APP_CONFIG.dataJson)

  DATA = data
  fixFuckingData()
  return DATA
}

// 修正不规范数据
function fixFuckingData () {
  getAvailableLocales().forEach(locale => {
    DATA[withLocaleSuffix('products', locale)].forEach(p => {
      p.rating ||= 0
      p.duration ||= 0
    })
  })
}

function withLocaleSuffix (key, locale) {
  const LOCALE_DATA_TABLE = {
    'en-US': 'en',
    'fr-FR': 'fr'
  }

  const suffix = locale === 'en-US' ? '' : _.capitalize(LOCALE_DATA_TABLE[locale])
  return key + suffix
}

export async function findProduct (id) {
  const product = (await findAllProducts()).find(it => it.id === id)
  if (!product) { throw new Error(`Product#${id} not found`) }
  return product
}

export async function findAllProducts () {
  const data = await getData()
  return data[withLocaleSuffix('products', getLocale())]
}

export async function findTagTree () {
  const data = await getData()
  return data[withLocaleSuffix('tagTree', getLocale())]
}
