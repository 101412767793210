<template>
<header class="app-header">
  <div class="container">
    <div class="wrapper">
      <router-link class="logo" :to="{ name: 'home' }">
        <img src="@/assets/logo.png">
      </router-link>
      <img src="@/assets/icons/home.png">
    </div>
  </div>
</header>
</template>

<style lang="scss" scoped>
.app-header {
  flex: 0 0 auto;
  border-bottom: 1px solid #eee;
  .wrapper {
    padding: 20px 0;
    display: flex; justify-content: space-between; align-items: center;
  }
  .logo {
    display: inline-block;
    img { width: 200px; }
  }
}
</style>
