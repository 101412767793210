import dayjs from 'dayjs'
import dayjsDuration from 'dayjs/plugin/duration'
import dayjsRelativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(dayjsDuration)
dayjs.extend(dayjsRelativeTime)

export function parseProductAttributes (product) {
  return {
    hasFreeCancellation: product.productCancels.some(it => it.percentageRefundable >= 100),
    hasMobile: /MOBILE/.test(product.ticketTypes),
    duration: dayjs.duration(product.duration, 'm').locale('en').humanize(),
    guideList: product.productLanguageGuides.map(it => it.language)
  }
}
