export default [
  {
    path: '/:locale?',
    component: require('@/components/Layout/AppLayout').default,
    children: [
      {
        path: '',
        name: 'home',
        component: require('@/views/HomeView').default
      },
      {
        path: 'search',
        name: 'list',
        component: require('@/views/ListView').default
      },
      {
        path: 'tours/:id',
        name: 'show',
        component: require('@/views/ShowView').default
      }
    ]
  }
]
