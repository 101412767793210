<template>
<div class="rate-star">
  <i v-for="(icon, idx) of icons" :key="idx" :class="['fas', icon]"/>
</div>
</template>

<script>
export default {
  props: {
    value: { type: Number, default: 0 },
    max: { type: Number, default: 5 }
  },

  computed: {
    icons () {
      const list = []

      for (let n = 0; n < this.max; n++) {
        const delta = this.value - n

        if (delta <= 0) {
          list.push('fa-star blank')
        } else if (delta > 0 && delta < 1) {
          list.push('fa-star-half-stroke')
        } else {
          list.push('fa-star')
        }
      }

      return list
    }
  }
}
</script>

<style lang="scss" scoped>
.rate-star {
  display: inline-flex;
  color: #ffd938;
  .blank { color: #ccc; }
}
</style>
