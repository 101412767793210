<template>
<div class="attraction-list">
  <div class="container">
    <div class="tags-wrapper">
      <div class="tag-prev fas fa-angle-left" :id="tagPrevId"/>
      <div class="tag-next fas fa-angle-right" :id="tagNextId"/>
      <swiper class="tags-swiper"
          :modules="modules"
          :navigation="{ prevEl: `#${tagPrevId}`, nextEl: `#${tagNextId}` }"
          :slides-per-view="2"
          :space-between="20"
          :breakpoints="{
            576: { slidesPerView: 3 },
            768: { slidesPerView: 5 },
            992: { slidesPerView: 8 }
          }">
        <swiper-slide v-for="tag of tags" :key="tag.id">
          <router-link :to="{ name: 'list', query: { tag: tag.id } }"
              class="tag-link" :title="tag.name">
            {{ tag.name }}
          </router-link>
        </swiper-slide>
      </swiper>
    </div>

    <div class="d-flex justify-content-between align-items-center my-4">
      <h4 class="mb-0">{{ $t('home_view.top_attractions') }}</h4>
      <router-link :to="{ name: 'list' }" class="btn btn-primary">
        {{ $t('home_view.explore_all') }}
      </router-link>
    </div>

    <div class="row g-4">
      <div class="col-md-6 col-lg-4 col-xl-3" v-for="p of products" :key="p.id">
        <div class="tour" @click="$router.push({ name: 'show', params: { id: p.id } })">
          <div class="tour-image">
            <img :src="p.imageUrl">
          </div>
          <div class="tour-body">
            <div class="title">{{ p.title }}</div>
            <div class="attrs">
              <div class="attrs-item" v-if="p.attributes.hasFreeCancellation">
                <img src="@/assets/icons/free_cancellation.png"> {{ $t('shared.free_cancellation') }}
              </div>
              <div class="attrs-item" v-if="p.attributes.hasMobile">
                <img src="@/assets/icons/mobile_ticketing.png"> {{ $t('shared.mobile_ticketing') }}
              </div>
              <div class="attrs-item">
                <img src="@/assets/icons/duration.png"> {{ $t('shared.duration') }} {{ p.attributes.duration }}
              </div>
            </div>
            <div>
              <rate-star :value="p.rating" class="me-2"/>
              <span class="me-3">{{ p.rating }}</span>
              ({{ $t('shared.x_reviews', { count: p.totalReviews }) }})
            </div>
            <div class="price">
              <span>{{ $t('shared.from') }} US$ {{ p.price }}</span>
              {{ $t('shared.per_person') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import _ from 'lodash'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from 'swiper'
import RateStar from '@/components/RateStar'
import { findTagTree, findAllProducts } from '@/lib/data'
import { parseProductAttributes } from '@/lib/helper'

export default {
  components: { Swiper, SwiperSlide, RateStar },

  data () {
    return {
      modules: [Navigation],
      swiperId: _.uniqueId('attraction_tag_'),
      tags: [],
      products: []
    }
  },

  mounted () {
    this.loadData()
  },

  computed: {
    tagPrevId () {
      return `${this.swiperId}_prev`
    },

    tagNextId () {
      return `${this.swiperId}_next`
    }
  },

  methods: {
    async loadData () {
      this.tags = (await findTagTree()).map(it => _.pick(it, 'id', 'name'))

      this.products = (await findAllProducts()).map(p => ({
        ...p,
        imageUrl: process.env.VUE_APP_DATA_URL + p.imageSource,
        attributes: parseProductAttributes(p)
      })).sort((a, b) => {
        if (a.totalReviews === b.totalReviews) {
          return b.rating - a.rating
        } else {
          return b.totalReviews - a.totalReviews
        }
      }).slice(0, 8)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/bootstrap_tools";

.attraction-list {
  .tags-wrapper {
    position: relative;
    .tags-swiper { margin: 0 30px; }
    .tag-prev, .tag-next {
      position: absolute; top: 50%;
      transform: translateY(-50%);
      font-size: 30px;
      cursor: pointer;
      color: $primary;
      &.swiper-button-disabled { color: $gray-500; }
    }
    .tag-prev { left: 0; }
    .tag-next { right: 0; }
    .tag-link {
      overflow: hidden; text-overflow: ellipsis; white-space: nowrap;
      text-align: center; text-decoration: none;
      display: block;
      border: 1px solid #dcdfe4;
      border-radius: 5px;
      padding: 10px 20px;
      color: #333;
      transition: all .3s;
      &:hover { background: #c7e2ff; }
    }
  }

  .tour {
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 100%;
    cursor: pointer;
    transition: all .3s;
    &:hover {
      box-shadow: 0 0 20px rgba(0, 0, 0, .15);
      .tour-image img { transform: scale(1.2); }
    }
    &-image {
      height: 120px;
      overflow: hidden;
      img {
        display: block; width: 100%; height: 100%;
        object-fit: cover;
        transition: all .3s;
      }
    }
    &-body {
      height: calc(100% - 120px);
      padding: 20px 15px;
      font-size: 14px;
      display: flex; flex-direction: column;
      .title { font-size: 16px; font-weight: 500; margin-bottom: 10px; }
      .attrs {
        margin: auto 0 20px 0;
        &-item {
          display: flex; align-items: center;
          &:not(:last-child) { margin-bottom: 5px; }
          img { width: 22px; margin-right: 10px; }
        }
      }
      .price {
        span { font-size: 20px; font-weight: 700; }
      }
    }
  }
}
</style>
