<template>
<app-header/>

<main class="app-main">
  <router-view/>
</main>

<app-footer/>
</template>

<script>
import { setLocale, defaultLocale } from '@/lib/i18n'
import AppHeader from './AppHeader'
import AppFooter from './AppFooter'

export default {
  components: { AppHeader, AppFooter },

  beforeRouteEnter (to, from) {
    setLocale(to.params.locale || defaultLocale())
  }
}
</script>

<style lang="scss" scoped>
.app-main {
  flex: 1 1 auto;
}
</style>
