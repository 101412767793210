<template>
<div class="main-banner mb-4" :style="bannerStyle()">
  <div class="container">
    <div class="row">
      <!-- <div class="col-md-6 col-lg-4">
        <form class="search-box" @submit.prevent="handleSubmit">
          <div class="">
            <select class="form-select">
              <option selected>{{ title }}</option>
            </select>
          </div>
          <div class="d-flex">
            <input type="date" class="form-control me-3">
            <button class="btn btn-primary" type="submit">{{ $t('home_view.search') }}</button>
          </div>
        </form>
      </div> -->
      <div class="col-md-6 col-lg-8 order-first order-md-last mb-3 mb-md-0">
        <div class="info">
          <h1 class="title">{{ title }}</h1>
          <div class="desc">{{ desc }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      title: this.$t(`config.${window.APP_CONFIG.name}.title`),
      desc: this.$t(`config.${window.APP_CONFIG.name}.desc`)
    }
  },

  methods: {
    bannerStyle () {
      const linearLayer = 'linear-gradient(90deg,rgba(0,0,0,.8),rgba(0,0,0,.793082) 6.67%,rgba(0,0,0,.771592) 13.33%,rgba(0,0,0,.734672) 20%,rgba(0,0,0,.682071) 26.67%,rgba(0,0,0,.61458) 33.33%,rgba(0,0,0,.534493) 40%,rgba(0,0,0,.445847) 46.67%,rgba(0,0,0,.354153) 53.33%,rgba(0,0,0,.265507) 60%,rgba(0,0,0,.18542) 66.67%,rgba(0,0,0,.117929) 73.33%,rgba(0,0,0,.0653279) 80%,rgba(0,0,0,.028408) 86.67%,rgba(0,0,0,.007) 93.33%,transparent)'
      const imageLayer = `url("${process.env.BASE_URL}${window.APP_CONFIG.banner}") no-repeat center / cover`

      return {
        background: `${linearLayer}, ${imageLayer}`
      }
    },

    handleSubmit (ev) {
      this.$router.push({ name: 'list' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/bootstrap_tools";

.main-banner {
  padding: 40px 0;

  .search-box {
    background: #1a2b49;
    padding: 30px 20px;
  }

  .info {
    color: white;
    .title { font-size: 48px; }
    .desc { font-size: 20px; }
  }

  @include media-breakpoint-up (md) {
    height: 360px;
    .container, .row { height: 100%; }
    .row { align-items: center; }
  }

  @include media-breakpoint-up (lg) {
    height: 480px;
    .info {
      .title { font-size: 64px; }
      .desc { font-size: 24px; }
    }
  }
}
</style>
