<template>
<div class="list-view py-4">
  <div class="container">
    <nav class="mb-4">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'home' }">{{ $t('shared.breadcrumb.home') }}</router-link>
        </li>
        <li class="breadcrumb-item active">{{ pageTitle }}</li>
      </ol>
    </nav>

    <div class="row g-3">
      <div class="col-lg-3">
        <div class="card mb-3">
          <div class="card-header">{{ $t('list_view.price') }}</div>
          <div class="card-body">
            <div class="d-flex justify-content-between mb-2" v-if="priceRange">
              <div class="small">USD$ {{ priceRange[0] }}</div>
              <div class="small">USD$ {{ priceRange[1] }}</div>
            </div>
            <el-slider v-model="priceRange" range :min="priceRangeLimit[0]" :max="priceRangeLimit[1]"/>
          </div>
        </div>

        <div class="card mb-3">
          <div class="card-header">{{ $t('list_view.rating') }}</div>
          <div class="card-body">
            <el-slider v-model="ratingRange" range :min="0" :max="5" :step="0.1"/>
          </div>
        </div>

        <div class="btn btn-primary d-block mb-3" @click="handleConditionChange">{{ $t('list_view.apply') }}</div>

        <div class="tags-list">
          <div v-for="t of tags" :key="t.id"
              class="tag-link"
              :class="{ active: t.id === tagId }"
              @click="changeTag(t.id)">
            {{ t.name }}
          </div>
        </div>
      </div>

      <div class="col-lg-9">
        <h5>{{ pageTitle }}</h5>

        <div class="d-lg-flex justify-content-between align-items-center mb-3">
          <div class="mb-3 mb-lg-0">
            <span>{{ $t('list_view.x_results', { count: total }) }}</span>
            <span class="mx-3">/</span>
            <span class="tag-link active" @click="changeTag(null)">{{ $t('shared.all') }} {{ pageTitle }}</span>
          </div>
          <div class="d-flex align-items-center small">
            <div class="text-nowrap me-3">{{ $t('list_view.sort_by') }}</div>
            <select v-model="sort" class="form-select form-select-sm" @change="handleConditionChange">
              <option v-for="opt of sortOptions" :key="opt.value.join('-')"
                  :value="opt.value">
                {{ opt.label }}
              </option>
            </select>
          </div>
        </div>

        <div class="mb-3">
          <div class="tours-item" v-for="p of products" :key="p.id"
              @click="$router.push({ name: 'show', params: { id: p.id } })">
            <div class="tours-item-image">
              <img :src="p.imageUrl">
            </div>
            <div class="tours-item-body">
              <div class="info-left">
                <div class="title">{{ p.title }}</div>
                <div class="attrs">
                  <div class="attrs-item" v-if="p.attributes.hasFreeCancellation">
                    <img src="@/assets/icons/free_cancellation.png"> {{ $t('shared.free_cancellation') }}
                  </div>
                  <div class="attrs-item" v-if="p.attributes.hasMobile">
                    <img src="@/assets/icons/mobile_ticketing.png"> {{ $t('shared.mobile_ticketing') }}
                  </div>
                  <div class="attrs-item">
                    <img src="@/assets/icons/duration.png"> {{ $t('shared.duration') }} {{ p.attributes.duration }}
                  </div>
                </div>
              </div>
              <div class="info-right">
                <div class="reviews">
                  <div><rate-star :value="p.rating" class="me-2"/>{{ p.rating }}</div>
                  <div class="number">({{ $t('shared.x_reviews', { count: p.totalReviews }) }})</div>
                </div>
                <div class="price">
                  <div>{{ $t('shared.from') }}</div>
                  <div class="highlight">US$ {{ p.price }}</div>
                  <div>{{ $t('shared.per_person') }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <el-pagination
          background hide-on-single-page
          layout="prev,pager,next"
          :pager-count="5"
          v-model:current-page="page"
          v-model:page-size="pageSize"
          :total="total"
          @current-change="loadProducts"
          @size-change="handleConditionChange"/>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import _ from 'lodash'
import { findAllProducts, findTagTree } from '@/lib/data'
import { parseProductAttributes } from '@/lib/helper'
import RateStar from '@/components/RateStar'

export default {
  components: { RateStar },

  data () {
    return {
      pageTitle: this.$t(`config.${window.APP_CONFIG.name}.list_title`),

      tags: [],
      tagId: null,

      products: [],
      page: 1,
      pageSize: 10,
      total: 0,

      sort: ['price', 'asc'],
      sortOptions: [
        { label: `${this.$t('list_view.price')} - ${this.$t('list_view.sort_asc')}`, value: ['price', 'asc'] },
        { label: `${this.$t('list_view.price')} - ${this.$t('list_view.sort_desc')}`, value: ['price', 'desc'] },
        { label: `${this.$t('list_view.rating')} - ${this.$t('list_view.sort_asc')}`, value: ['rating', 'asc'] },
        { label: `${this.$t('list_view.rating')} - ${this.$t('list_view.sort_desc')}`, value: ['rating', 'desc'] }
      ],

      priceRange: null,
      priceRangeLimit: [0, 100],
      ratingRange: [0, 5]
    }
  },

  mounted () {
    this.loadData()
  },

  methods: {
    async loadData () {
      if (this.$route.query.tag) {
        this.tagId = parseInt(this.$route.query.tag)
      }

      this.tags = (await findTagTree()).map(it => ({
        id: it.id,
        name: it.name,
        idList: this.buildTagIdList(it)
      }))

      await this.loadProducts()
      this.priceRange = this.priceRangeLimit
    },

    async loadProducts () {
      const allProducts = (await findAllProducts()).map(p => ({
        ...p,
        imageUrl: process.env.VUE_APP_DATA_URL + p.imageSource,
        attributes: parseProductAttributes(p)
      }))

      // 排序
      allProducts.sort((a, b) => {
        const field = this.sort[0]

        if (this.sort[1] === 'asc') {
          return a[field] - b[field]
        } else {
          return b[field] - a[field]
        }
      })

      // 过滤
      const filteredProducts = allProducts
        .filter(p => {
          if (!this.tagId) { return true }
          const idList = _.find(this.tags, it => it.id === this.tagId).idList
          return !!_.intersection(idList, p.tags.map(it => it.id)).length
        })
        .filter(p => {
          if (this.priceRange) {
            return p.price >= this.priceRange[0] && p.price <= this.priceRange[1]
          } else {
            return true
          }
        })
        .filter(p => p.rating >= this.ratingRange[0] && p.rating <= this.ratingRange[1])

      // 分页
      const startIdx = (this.page - 1) * this.pageSize
      const endIdx = startIdx + this.pageSize
      const pagedProducts = filteredProducts.slice(startIdx, endIdx)
      this.products = pagedProducts
      this.total = filteredProducts.length

      // 价格范围
      const priceList = allProducts.map(p => p.price)
      this.priceRangeLimit = priceList.length
        ? [Math.floor(Math.min(...priceList)), Math.ceil(Math.max(...priceList))]
        : [0, 100]
    },

    buildTagIdList (tag, list = []) {
      list.push(tag.id)

      if (tag.children) {
        tag.children.forEach(it => this.buildTagIdList(it, list))
      }

      return list
    },

    handleConditionChange () {
      this.page = 1
      this.loadProducts()
    },

    changeTag (id) {
      this.tagId = id
      this.handleConditionChange()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/bootstrap_tools";

.list-view {
  .tags-list {
    > div:not(:last-child) { margin-bottom: 10px; }
  }
  .tag-link {
    cursor: pointer;
    &:hover { text-decoration: underline; }
    &.active { color: $primary; }
  }

  .tours-item {
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: all .3s;
    &:not(:last-child) { margin-bottom: 20px; }
    &:hover { box-shadow: 0 0 20px rgba(0, 0, 0, .15); }
    &-image {
      height: 120px;
      overflow: hidden;
      img {
        display: block;
        width: 100%; height: 100%;
        object-fit: cover;
      }
    }
    &-body {
      padding: 20px 15px;
      .info-left {
        margin-bottom: 20px;
        .title { font-weight: 500; margin-bottom: 10px; }
        .attrs {
          &-item {
            display: flex; align-items: center;
            &:not(:last-child) { margin-bottom: 5px; }
            img { width: 22px; margin-right: 10px; }
          }
        }
      }
      .info-right {
        display: flex; justify-content: space-between;
        font-size: 14px;
        text-align: right;
        .reviews {
          .number { font-size: 12px; color: #63687a; }
        }
        .price {
          .highlight { font-size: 24px; font-weight: 700; }
        }
      }
    }
    @include media-breakpoint-up (lg) {
      display: flex;
      &-image { width: 180px; height: 200px; }
      &-body {
        flex: 1 1 auto;
        display: flex;
        .info-left { flex: 1 0 auto; }
        .info-right {
          display: block;
          .reviews { margin-bottom: 20px; }
        }
      }
    }
  }
}
</style>
