import { createApp } from 'vue'
import ElementPlus from 'element-plus'

import App from '@/App'
import router from '@/router'
import store from '@/store'
import i18n from '@/lib/i18n'

import 'swiper/css/bundle'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fancyapps/ui/dist/fancybox.css'
import 'element-plus/dist/index.css'
import '@/styles/app.scss'

createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .use(ElementPlus)
  .mount('#app')
