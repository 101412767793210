<template>
<footer class="app-footer">
  <div class="container">
    Copyright © {{ year }} DiscoverCity.com
  </div>
</footer>
</template>

<script>
export default {
  data () {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss" scoped>
.app-footer {
  flex: 0 0 auto;
  padding: 30px 0;
  background: #1a2b49; color: white;
  text-align: center;
}
</style>
